import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";

export default class home extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <div>

                   <section class="bg-home d-table w-100" style={{backgroundImage: "url('images/home/bg-hero.jpg')"}} id="home">
            <div class="bg-overlay"></div>
            <div class="container position-relative" style={{zIndex: 1}}>
                <div class="row justify-content-center pt-4">
                    <div class="col-md-8">
                        <div class="title-heading text-center">

                            <h5 class="text-light mt-3">WELCOME !</h5>
                            <h3 class="heading text-light">National Initiative for Artificial Intelligence and Security</h3>
                          
                            <div class="mt-4">
                                <Link to="/application-form" class="btn btn-custom mr-2" style={{fontSize:20+"px"}}>Apply Now</Link>
                              
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="row justify-content-center">
                    <div class="col-md-1">
                        <div class="mouse-down text-center">
                            <a href="#about" class="text-center text-white"><i class="fas fa-angle-down rounded-pill"></i></a>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="business-home-shape">
                <img src="images/shape-r1.png" alt="" class="img-fluid mx-auto d-block"/>
            </div>
        </section>
               {/* <!-- HOME START--> */}
       
        {/* <!-- HOME END--> 

        <!-- FEATURES START --> */}
        <section class="section-two bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="feature position-relative">
                            <div class="fe-icon">
                                <h1><i class="fas fa-globe float-left mr-3"></i></h1>
                            </div>
                            <div class="fe-head overflow-hidden d-block">
                                <h5>Our Mission</h5>
                                <p class="text-muted mb-0">NIAIS mission is to train youth of Pakistan to reform technical reinforcement of emerging technologies contributing in the progress of Pakistan.</p>
                                <span class="font-weight-bold">Mission</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="feature position-relative">
                            <div class="fe-icon">
                                <h1><i class="fas fa-hands-helping float-left mr-3"></i></h1>
                            </div>
                            <div class="fe-head overflow-hidden d-block">
                                <h5>Our Uniqueness</h5>
                                <p class="text-muted mb-0">
                                	NIAIS provide hands on training.Our training programs are comprised of 20% theory and 80% practical work.Minimum fee with world class training 

                                    </p>
                                <span class="font-weight-bold">Uniqueness</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div class="feature position-relative">
                            <div class="fe-icon">
                                <h1><i class="fas fa-chart-line float-left mr-3"></i></h1>
                            </div>
                            <div class="fe-head overflow-hidden d-block">
                                <h5>Our Programs</h5>
                                <p class="text-muted mb-0">
                                    <ul>
                                        <li>1 year Program</li>
                                        <li>2 Months Fast Track Program</li>

                                    </ul>
                                </p>
                                <span class="font-weight-bold">Programs</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- FEATURES END -->

        <!-- ABOUT US START --> */}
        <section class="section">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-6">
                        <div class="position-relative ml-md-4">
                                <iframe width="420" height="315"
                                src="https://www.youtube.com/embed/OS34q7HCprI">
                               </iframe>
                            {/* <img src="images/about/about-pic.jpg" class="img-fluid rounded shadow" alt=""/>
                            <div class="play-icon">
                                <a href="http://vimeo.com/287684225" class="play-btn video-play-icon">
                                    <i class="mdi mdi-play text-primary rounded-pill bg-white shadow"></i>
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="section-title ml-lg-3 mt-sm-30">
                            <h4 class="title mb-4 font-weight-normal">NIAIS is revolutionising and preparing skilled force for industrial 4.0 and emerging technologies</h4>
                            <p class="text-muted">NIAIS mission is to train youth of Pakistan to reform technical reinforcement of emerging technologies contributing in the progress of Pakistan. NIAIS aims to enhance a better image on global reforms by adapting the era of 4th Industrial Revolution.</p>
                            {/* <div class="mt-4">
                                <a href="#" class="btn btn-custom">Our Mission</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- ABOUT US END -->

        <!-- SERVICES START --> */}
        {/* <section class="section bg-light"> 
            <div class="container"> 
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title mb-4 pb-2 text-center">
                            <h3 class="title text-uppercase mb-4">Our Services</h3>
                            <p class="text-muted mx-auto para-desc mb-0">Donec sodales sagittis magna. Excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fas fa-chalkboard-teacher text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Fast processing</h4>
                                <p class="text-muted mb-0">There are many variations of passages of Ipsum available alteration in some form.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fas fa-hand-holding-heart text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Premium Sliders</h4>
                                <p class="text-muted mb-0">Contrary to popular belief, it has roots it has roots in a piece of classical in a piece of classical Latin literature.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fas fa-wrench text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Free Support</h4>
                                <p class="text-muted mb-0">No one rejects, dislikes, or avoids pleasure itself, because it has roots in a piece of classical it is pleasure.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fab fa-digital-ocean text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Clean Modern Code</h4>
                                <p class="text-muted mb-0">Nam libero tempore, cum soluta nobis est eligendi optio it has roots in a cumque nihil impedit quo minus.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fas fa-layer-group text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Flexible Layouts</h4>
                                <p class="text-muted mb-0">On the other hand, we indignation and dislike men it has roots in a piece of are so beguiled and demoralized.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="types-of-service p-4 rounded border">
                            <div class="service-icon text-custom">
                                <i class="fab fa-sketch text-custom float-left mb-0 mr-3"></i>
                            </div>
                            <div class="service-head overflow-hidden d-block">
                                <h4 class="title">Modern Design</h4>
                                <p class="text-muted mb-0">Nor again is there anyone who loves or pursues it has roots in a piece of classical to obtain pain of itself.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        {/* <!-- SERVICES END -->

        <!-- CTA START --> */}
        <section class="section" style={{padding:15+'px'}}>
            {/* <div class="bg-overlay"></div> */}
            <div class="">
                <div class="row">
                    <div class="col-lg-12">
                        {/* <div class="section-title text-center text-white"> */}
                            {/* <h3 class="title mb-4">Trending ideas and inventions always make us feel proud</h3>
                            <p class="mx-auto para-desc text-white-50">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> */}
                            <iframe width="100%" height="540"
                                src="https://www.youtube.com/embed/uv6xdDzRufA">
                               </iframe>
                            {/* <a href="http://vimeo.com/287684225" class="play-btn border mt-2 video-play-icon">
                                <i class="mdi mdi-play text-white"></i>
                            </a> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- CTA END -->

        <!-- WORK START --> */}
        <section class="section" style={{paddingTop:0+"px"}}> 
            <div class="container"> 
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title mb-4 pb-2 text-center">
                        <span class="w-100 btn btn-new heading_dis" value="">Our Programs</span>
                            {/* <p class="text-muted mx-auto para-desc mb-0 categories border rounded py-2 px-3 text-uppercase active">One Year Programs</p> */}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-8 mt-4 mx-auto">
                        <div class="text-center">
                            <ul class="col container-filter portfolioFilter list-unstyled mb-0" id="filter">
                                <li class="d-inline-block pb-3 w-100"><a class="categories border rounded py-2 px-3 text-uppercase active" data-filter="*" style={{fontSize:20+"px"}}>One Year Programs</a></li>
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="container-fluid">
                <div class="port portfolio-masonry">
                    <div class="portfolioContainer row">
                    <div class="col-lg-6 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/main/artificial-intelligence.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Artificial Intelligence</a></h6>
                                  
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                   <img src="images/courses/main/cybersecurity.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">National Cyber Security</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="portfolioContainer row">
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                   <img src="images/courses/main/fullstack.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">FullStack Web Development</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/main/cloud-computing.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Cloud Computing</a></h6>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-2 account">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/main/internet.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Internet Of Things</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                        
                                   
                </div>
                <div class="row">
                    <div class="col-md-12 mt-4 pt-2">
                        <div class="text-center">
                            <a class="btn btn-custom w-100 heading_dis" style={{fontSize:20+"px"}}>Short Courses</a>
                        </div>
                    </div>
                </div>

                <div class="portfolioContainer row">
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                                <Link class="mfp-image" to="/application-form">
                                   <img src="images/courses/FEB.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Frontend Development (Basic)</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/FEA.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Frontend Development (Advance)</a></h6>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 mt-4 pt-2 account">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/Reactjs.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">React.js</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                   <img src="images/courses/Python.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Python With Django</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/laravelwithphp.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Laravel with PHP</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 account">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/wordpress.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Wordpress (Basic to Advance)</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="portfolioContainer row">
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 part">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                   <img src="images/courses/Oracle.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Oracle (DMS)</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 account">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/amazon.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark">Amazon Virtual Assistant PL,WS</a></h6>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 mt-4 pt-2 account">
                            <div class="portfolio-box rounded position-relative overflow-hidden">
                            <Link class="mfp-image" to="/application-form">
                                    <img src="images/courses/SEO.png" class="img-fluid" alt="member-image"/>
                                </Link>
                                <div class="gallary-title text-center bg-light py-4">
                                    <h6><a  class="text-dark"></a>SEO</h6>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
            </div>
        </section>
        {/* <!-- WORK END -->
        <!-- COUNTER START --> */}
        <section class="section">
            <div class="bg-overlay"></div>
            <div class="container">
                <div class="row" id="counter">
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="text-center py-4 text-white">
                            <h1><i class="pe-7s-users"></i></h1>
                            <h2 class="counter-value">10000 +</h2>
                            <p class="counter-name mb-0">Visitors</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="text-center py-4 text-white">
                            <h1><i class="pe-7s-users"></i></h1>
                            <h2 class="counter-value">7000 +</h2>
                            <p class="counter-name mb-0">Enrolled Students</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6 mt-4 mt-sm-0">
                        <div class="text-center py-4 text-white">
                            <h1><i class="pe-7s-users"></i></h1>
                            <h2 class="counter-value" >500 +</h2>
                            <p class="counter-name mb-0">Associates</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6 mt-4 mt-sm-0">
                        <div class="text-center py-4 text-white">
                            <h1><i class="pe-7s-notebook"></i></h1>
                            <h2 class="counter-value" >19 +</h2>
                            <p class="counter-name mb-0">Courses</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      

        {/* <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title mb-4 pb-2 text-center">
                            <h3 class="title text-uppercase mb-4">Latest Blog & News</h3>
                            <p class="text-muted mx-auto para-desc mb-0">Donec sodales sagittis magna. Excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="blog-post rounded border position-relative overflow-hidden d-block">
                            <img src="images/blog/blog-1.jpg" class="img-fluid" alt=""/>

                            <div class="content p-3">
                                <ul class="list-unstyled">
                                    <li class="float-right"><i class="mdi mdi-calendar"></i> Jan 01, 2020</li>
                                    <li><a href="#" class="text-custom "><i class="mdi mdi-bookmark"></i> Branding</a></li>
                                </ul>

                                <h5><a href="#" class="text-dark title"> The business woman hard work</a></h5>
                                <p class="text-muted">These cases are perfectly simple and easy to distinguish. In a free hour, when to do what we like best.</p>

                                <ul class="post-meta pt-2 border-top list-unstyled mb-0">
                                    <li class="float-right"><a href="#" class="readmore text-dark">Read More <i class="mdi mdi-chevron-right"></i></a></li>
                                    <li>
                                        <ul class="list-unstyled">
                                            <li class="d-inline-block mr-2"><a href="#" class="like text-dark"><i class="mdi mdi-heart-outline"></i> <small>29</small></a></li>
                                            <li class="d-inline-block"><a href="#" class="comment text-dark"> <i class="mdi mdi-comment-outline"></i>
                                                <small>40</small></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="blog-post rounded border position-relative overflow-hidden d-block">
                            <img src="images/blog/blog-2.jpg" class="img-fluid" alt=""/>

                            <div class="content p-3">
                                <ul class="list-unstyled">
                                    <li class="float-right"><i class="mdi mdi-calendar"></i> Jan 01, 2020</li>
                                    <li><a href="#" class="text-custom "><i class="mdi mdi-bookmark"></i> Branding</a></li>
                                </ul>

                                <h5><a href="#" class="text-dark title"> The business woman hard work</a></h5>
                                <p class="text-muted">These cases are perfectly simple and easy to distinguish. In a free hour, when to do what we like best.</p>

                                <ul class="post-meta pt-2 border-top list-unstyled mb-0">
                                    <li class="float-right"><a href="#" class="readmore text-dark">Read More <i class="mdi mdi-chevron-right"></i></a></li>
                                    <li>
                                        <ul class="list-unstyled">
                                            <li class="d-inline-block mr-2"><a href="#" class="like text-dark"><i class="mdi mdi-heart-outline"></i> <small>29</small></a></li>
                                            <li class="d-inline-block"><a href="#" class="comment text-dark"> <i class="mdi mdi-comment-outline"></i>
                                                <small>40</small></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-6 mt-4 pt-2">
                        <div class="blog-post rounded border position-relative overflow-hidden d-block">
                            <img src="images/blog/blog-3.jpg" class="img-fluid" alt=""/>

                            <div class="content p-3">
                                <ul class="list-unstyled">
                                    <li class="float-right"><i class="mdi mdi-calendar"></i> Jan 01, 2020</li>
                                    <li><a href="#" class="text-custom "><i class="mdi mdi-bookmark"></i> Branding</a></li>
                                </ul>

                                <h5><a href="#" class="text-dark title"> The business woman hard work</a></h5>
                                <p class="text-muted">These cases are perfectly simple and easy to distinguish. In a free hour, when to do what we like best.</p>

                                <ul class="post-meta pt-2 border-top list-unstyled mb-0">
                                    <li class="float-right"><a href="#" class="readmore text-dark">Read More <i class="mdi mdi-chevron-right"></i></a></li>
                                    <li>
                                        <ul class="list-unstyled">
                                            <li class="d-inline-block mr-2"><a href="#" class="like text-dark"><i class="mdi mdi-heart-outline"></i> <small>29</small></a></li>
                                            <li class="d-inline-block"><a href="#" class="comment text-dark"> <i class="mdi mdi-comment-outline"></i>
                                                <small>40</small></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        */}
               {/* <!-- PARTNER END -->

        <!-- CONTACT END --> */}
        {/* <section class="section-two bg-custom">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="section-title text-center">
                            <h5 class="title text-light text-uppercase font-weight-normal">Do You Want To Work With Crezo ?</h5>
                            <div class="mt-4">
                                <a href="contact.html" class="btn btn-light">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    
            </div>
        )
    }
}
