import React, { Component } from 'react'
import Axios from 'axios'

export default class planTwoChallan extends Component {
	constructor(props)
	{
     super(props)
	 this.state={
		data:this.props.location.state
	 }
	
	}
	componentDidMount()
	{
		console.log(this.state,'challan-2-state')
	}

    render() {
		window.scrollTo(0, 0);

        return (
            <div>
                 <section class="section" id="contact">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-12"></div>
                	<div class="print-container clearfix">
		<div class="header">
		  <div class="content">
			<table style={{width:100+"%"}}>
			  <tr style={{width:100+"%"}} class="heading">
				<td colspan="3">

							<img class="invoice-logo" src="images/logo.png" height="60" alt="" />
				</td>
				<td class="text-right">
				  <div class="invoice-from" style={{maxWidth:400+"px"}}>
			
					<h3 class="mega">INVOICE</h3>
				<h6 class="grey">National Initiative for Artificial Intelligence and Security.</h6>
				<h6 class="grey">Bank Details</h6>
				<p><b>Bank AlFalah</b></p>
				<p>
				  <strong>Account Title: Niais 4.O</strong>
				  <br /> <strong>Account No: 56195001266204 </strong> 
					</p>
				  </div>
				  
				</td>
			  </tr>
			</table>
		  </div>
		</div>
		<div class="body">

		<div class="row">
			<div class="col-md-12">
		  <h4>Details</h4>
		  <div class="break-d">
		  <strong>Invoice No:</strong> {this.state.data.challan.challan_no}
			<br />
		
			<p><strong>Student Name:</strong> {this.state.data.StudentProfile.name}
		  </p>
		  
		  <table class="table summary-table">
		<thead>
		  <tr>
			<th>Course Name</th>
			<th>Fee </th>
			
		  </tr>
		</thead>
		<tbody>
			{this.state.data.StudentCourses.map((course,i)=>{return(
			<tr class="simple">
			<th scope="row">{course.courses.name}</th>
			<td>{course.courses.fee}</td>
			
			
		  </tr>)})}
		  
		 		</tbody>
	  </table>
	 
		  <div class="row">
			<div class="col-md-12">
			  <div class="other-rates clearfix">
			<dl class="dl-horizontal clearfix no-border">
			  <dt class="blue">Total</dt>
			  <dd><b>{this.state.data.challan.amount}</b></dd>
			</dl>
		  </div>
			</div>
			</div></div>
			<h4>Installment Details</h4>
		 
		  
		  
		  <table class="table summary-table">
		<thead>
		  <tr>
			<th>Installment</th>
			<th>Dues </th>
			
		  </tr>
		</thead>
		<tbody>
			{this.state.data.challan.installment.sort((a, b) => a.id > b.id ? 1:-1).map((Installment,i)=>{return(
			<tr class="simple">
			<th scope="row">{Installment.name}</th>
			<td>{Installment.amount}</td>
			
			
		  </tr>)})}
		  
		 		</tbody>
	  </table>
	  <div class="col-md-12">
		  
		  <p><b>Note:</b> 
		  The generated invoice slip is meant for information only. 
You cannot submit fee through this voucher. You need to fill bank deposit slip which is available in every Bank in their own format. 
After submitting you can upload deposit slip copy with the bank stamp and signature of bank officer to NIAIS on following <a href="http://application.niais.org/verification-form" target="_blank">Link</a>.
You can also submit via easypaisa or jazzcash and upload the screen shot.<b> Details of easypaisa and Jazzcash are as follows:</b>
<br></br><b>Easypaisa:</b> 
 03236200760
Hafiz Muhammad Waqas
<br></br>
<b>JazzCash:</b>
 03090666609
Hafiz Muhammad Waqas
.</p>
	  </div>
			<div class="col-md-12">
			<button className="submitBnt btn btn-submit w-100" onClick={() => window.print()}>
          Print
        </button>
			</div>
		  </div>
		  </div>

			  {/* <div class="break-d">
			<p><strong>Vendor Name:</strong> ABC Corporation
			<br />
			<strong>Invoice No:</strong> 12356633
		  </p>
		  
		  <table class="table summary-table">
		<thead>
		  <tr>
			<th>Contractor Name</th>
			<th>Hours Billed</th>
			<th>Bill Rate</th>
			<th>Amount</th>
		  </tr>
		</thead>
		<tbody>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		</tbody>
	  </table>
		  <div class="row">
			<div class="col-md-12">
			  <div class="other-rates clearfix">
			<dl class="dl-horizontal clearfix  no-border">
			  <dt class="blue">Total</dt>
			  <dd>$1234424</dd>
			</dl>
		  </div>
			</div>
		  </div>
		  </div>
			  <div class="break-d">
			<p><strong>Vendor Name:</strong> ABC Corporation
			<br />
			<strong>Invoice No:</strong> 12356633
		  </p>
		  
		  <table class="table summary-table">
		<thead>
		  <tr>
			<th>Contractor Name</th>
			<th>Hours Billed</th>
			<th>Bill Rate</th>
			<th>Amount</th>
		  </tr>
		</thead>
		<tbody>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		  <tr class="simple">
			<th scope="row">ABC Corporation</th>
			<td>9999</td>
			<td>$12442</td>
			<td>$12442</td>
		  </tr>
		</tbody>
	  </table>
		  <div class="row">
			<div class="col-md-12">
			  <div class="other-rates clearfix">
			<dl class="dl-horizontal clearfix no-border">
			  <dt class="blue">Total</dt>
			  <dd>$1234424</dd>
			</dl>
		  </div>
			</div>
		  </div>
		  </div>
		   */}
		</div>
	  
	  </div>
      </div></div></section>
            </div>
        )
    }
}
