import React, { Component } from 'react'
import {Helmet} from "react-helmet";

export default class howToApply extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <div>

             <section class="section apply" >
            {/* <div class="bg-overlay"></div> */}
            <div class="">
                <div class="row">
                <div class="col-lg-12">
                    <h2 className="apply-video">How To Apply</h2>
                    </div>
                    <div class="col-lg-12">
                        {/* <div class="section-title text-center text-white"> */}
                            {/* <h3 class="title mb-4">Trending ideas and inventions always make us feel proud</h3>
                            <p class="mx-auto para-desc text-white-50">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> */}
                            <iframe width="100%" height="540"
                                src="https://www.youtube.com/embed/ozWM-67w9Y8?autoplay=1">
                               </iframe>
                            {/* <a href="http://vimeo.com/287684225" class="play-btn border mt-2 video-play-icon">
                                <i class="mdi mdi-play text-white"></i>
                            </a> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>
    
 
            </div>
        )
    }
}
