import logo from './logo.svg';
import './App.css';
import { Router, Switch, Route } from "react-router-dom"
import Header from './pages/layouts/header'
import Footer from './pages/layouts/footer'
import Home from './pages/home'
import Applicationform from './pages/applicationform'
import Verificationform from './pages/verificationform'
import StudentCourses from './pages/studentCourses'
import PlanOneChallan from './pages/challan/planOneChallan'
import PlanTwoChallan from './pages/challan/planTwoChallan'
import HowToApply from './pages/howToApply'
import Login from './pages/login'
import Logout from './pages/logout'
import { history } from "./history"
function App() {
  return (
    <>
    <Router history={history}>
     <Header/>
     <Switch>
     <Route path="/" exact component={Login} />
     <Route path="/application-form" exact component={Login} />
     <Route path="/login"component={Login} />
     <Route path="/logout" component={Logout} />
     <Route path="/student-courses"  component={StudentCourses} />
     <Route path="/verification-form"  component={Verificationform} />
     <Route path="/my-invoice"  component={PlanOneChallan} />
     <Route path="/installment-invoice"  component={PlanTwoChallan} />
     <Route path="/how-to-apply"  component={HowToApply} />
     </Switch>
        <Footer/>

        </Router>
    </>
    
  );
}

export default App;
