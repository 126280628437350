import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Axios from "axios"
import swal from 'sweetalert';
import {history} from '../history'
export default class verificationform extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            cnic:'',
            email:'',
            challan_no:'',
            dos:'',
            tr_no:'',
          
            errors:{},
            file_viewer :"",
 
      file :false,

        }
        window.scrollTo(0, 0);
        this.loadFile = this.loadFile.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChange =this.onChange.bind(this)
    }
    loadFile(e) {
        let file  =  e.target.files[0];
        console.log(file,'file')
        if(file !=null){
          this.setState({
            file_viewer: URL.createObjectURL(file),
            file:file,
          })
        }
    } 
        
    
    onChange(event) {


        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
          [name]: value
        }, function () {
          console.log(this.state,'change-accours')
      });
        
      
      }

      onSubmit(e)
  { 
      console.log(this.state,'submit')
      e.preventDefault();
      const fd = new FormData(); 
      fd.append('cnic', this.state.cnic);
      fd.append('email', this.state.email);
      fd.append('challan_no', this.state.challan_no);
      fd.append('dos', this.state.dos);
      fd.append('tr_no', this.state.tr_no);
      fd.append('file', this.state.file);
      const url = window.url+'verification-request';
      Axios.post( url,fd, { headers: {
          'Content-Type': 'application/json',
      } } )  
      .then(res=>{
        
          if( res.data.success === true ) {
            swal({
                title: "Congratulations",
                text: "Verification Request has been Submitted Successfully!",
                icon: "success",
                button: "ok",
              });

              history.push('/')
                     }
          if( res.data.success === false ) {

             swal({
                title: "Error",
                text: res.data.message,
                icon: "error",
                button: "ok",
              });
           }
      })
      .catch(err=>{ 
   });
  }  

    render() {
        return (
            <div>
                <section class="section" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title  text-center">
                        <img src="images/logo.png" alt="missing_logo" height="60"/>
                            <h3 class="title text-uppercase ">Verification Form</h3>
                          
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                   
                    {/* <!-- col end --> */}
                    <div class="col-md-12 mt-4 pt-2">
                        <div class="custom-form">
                      

                            <form method="post" onSubmit={this.onSubmit} >
                                <div class="row">
                                   
                                <div class="col-md-4">
                                        <div class="form-group app-label">
                                        <FormControl fullWidth >
          
                                       <TextField
                                       onChange={this.onChange}
                                           name="cnic"
                                           label="CNIC"
                                           required
                                        type="text"
                                          />
                                    </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group app-label">
                                        <FormControl fullWidth >
          
                                       <TextField
                                        onChange={this.onChange}
                                           name="email"
                                           label="Email"
                                            type="email"
                                            required
                                          />
                                    </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group app-label">
                                        <FormControl fullWidth >
          
                                       <TextField
                                        onChange={this.onChange}
                                         name="challan_no"
                                          type="text"
                                           label="Invoice Number"
                                           required
                                          />
                                    </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group app-label">
                                        <FormControl fullWidth >
          
          <TextField
           onChange={this.onChange}
          type="date"
          focused
        //    onFocus={this.type='date'}
              label="Date Of Submission"
              name="dos"
              required
             />
       </FormControl>
                                        </div>
                                    </div>
                                   
                      
                                    <div class="col-md-6">
                                        <div class="form-group app-label">
                                        <FormControl fullWidth >
          
          <TextField
           onChange={this.onChange}
          type="text"
        //    onFocus={this.type='date'}
              label="Transaction / Deposit Slip Number"
              name="tr_no"
              required
             />
       </FormControl>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                    <FormControl fullWidth >
                                    <TextField focused type="file" required  onChange={this.loadFile} label="Upload File"/>
                                    </FormControl>
                                    </div>
                                    <div class="col-md-12">
                                        <img src={this.state.file_viewer} style={{maxWidth:100+"%", maxHeight:300+"px",objectFit:"contain"}} />
                                        </div> 
                                   </div>
                                <div class="row">
                                    <div class="col-md-12">
                                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-submit w-100" value="Request For Verify"/>

                                        <div id="simple-msg"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
            </div>
        )
    }
}
