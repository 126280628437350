import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { Button } from '@material-ui/core';

export default class header extends Component {
    render() {
        return (
            <div>
                 {/* // <!-- Loader --> */}
    {/* <div id="preloader">
        <div id="status">
            <div class="text-center"><img src="images/logo.png" height="40" alt=""/> </div>             
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div>
    </div>  */}

    {/* <!-- Tagline Start --> */}
    <div class="tagline">
        <div class="container">
            <div class="float-left">
                <div class="phone d-inline-block">
                    <a href="tel:+152534-468-854" class="text-white"><i class="fas fa-phone-volume"></i> +92 42 358 888 85</a>
                </div>
            </div>
            <div class="float-right">
                <ul class="top_socials list-unstyled mb-0">
                    <li class="list-inline-item text-right p-0 mr-0"><a href="https://www.facebook.com/niais.org/" target="_blank" class="text-white"><i class="fab fa-facebook-f"></i></a></li>
                    <li class="list-inline-item text-right p-0 mr-0"><a href="https://www.instagram.com/niaisofficial/?utm_medium=copy_link" target="_blank" class="text-white"><i class="fab fa-instagram"></i></a></li>
                    <li class="list-inline-item text-right p-0 mr-0"><a href="https://www.linkedin.com/company/niais" target="_blank" class="text-white"><i class="fab fa-linkedin"></i></a></li>
                </ul>
            </div>
            <div class="clear"></div>
        </div>
    </div>
    {/* <!-- Tagline End --> */}
    
    {/* <!-- Navigation Bar--> */}
    <header id="topnav" class="defaultscroll fixed-top navbar-sticky sticky">
        <div class="container">
            {/* <!-- Logo container--> */}
            <div>
                <Link to="/" class="logo">
                    <img src="images/logo.png" alt="missing_logo" height="60"/>
                </Link>
            </div>
            {/* <!-- End Logo container--> */}
            <div class="menu-extras">
                <div class="menu-item">
                    {/* <!-- Mobile menu toggle--> */}
                    <a class="navbar-toggle">
                    <div class="float-right">
                <ul class="top_socials list-unstyled mb-0">


                <li class="list-inline-item text-right p-0 mr-3"><Link to="/verification-form">Verifiy</Link></li>

                    <li class="list-inline-item text-right p-0 mr-3"><Link to="/application-form">Apply</Link></li>
                   
                    <li class="list-inline-item text-right p-0 mr-3"><Link to="/login" > Login</Link> </li>
                    <li class="list-inline-item text-right p-0 mr-3 "><Link to="/how-to-apply" className="text-danger" > ?</Link> </li>
                </ul>
            </div> 
                    </a>
                    {/* <!-- End mobile menu toggle--> */}
                </div>
            </div>

            <div id="navigation">
                {/* <!-- Navigation Menu--> */}
                <ul class="navigation-menu">
               
                <li class="has-submenu">
                        <Link to="/verification-form">Verification Form</Link> </li>

                    <li class="has-submenu">
                        <Link to="/application-form">Apply Form</Link>
                         </li>
                         <li class="has-submenu">
                        <Link to="/login"> Login</Link> </li>
                        <li><Link to="how-to-apply"><span className="">How To Apply</span></Link></li>
                         {/* {localStorage.getItem('data')?<li><Link to="/logout">Logout</Link></li>:""} */}
                       
                </ul>
                {/* <!-- End navigation menu--> */}
            </div>
        </div>
    </header>
    {/* <!-- End Navigation Bar--> */}
 
            </div>
        )
    }
}
