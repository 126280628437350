import React, { Component } from 'react'
import {Link} from 'react-router-dom'
export default class footer extends Component {
    render() {
        return (
            <div>
                 {/* <!-- CONTACT END -->

        <!-- FOOTER START --> */}
        <footer class="footer bg-dark">
            <div class="container">
                {/* <!--Footer Info --> */}
                <div class="row footer-info">
                    <div class="col-lg-4 col-md-12">
                        <Link to="/" class="logo">
                        <img src="images/logo.png" alt="missing_logo" height="60"/>
                        </Link>
                        <p class="text-foot mt-4">NIAIS is revolutionising and preparing skilled force for industrial 4.0 and emerging technologies</p>
                        <div>
                            <ul class="list-unstyled social-icon social mt-4 mb-0">
                              
                                <li class="list-inline-item ml-2"><a href="https://www.facebook.com/niais.org/" target="_blank" class="rounded"><i class="fab fa-facebook-f"></i></a></li>

                                <li class="list-inline-item ml-2"><a href="https://www.instagram.com/niaisofficial/?utm_medium=copy_link" target="_blank" class="rounded"><i class="fab fa-instagram"></i></a></li>
                                <li class="list-inline-item ml-2"><a href="https://www.linkedin.com/company/niais" target="_blank" class="rounded"> <i class="fab fa-linkedin"></i></a></li>

                               
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="footer-head">
                            <h6 class="title mb-0">Niais Application Form</h6>
                        </div>
                        <div class="footer-item mt-4">
                            <ul class="list-unstyled mb-0">
                                <li><Link to="/application-form"><i class="mdi mdi-chevron-right"></i> Apply Now</Link></li>
                                <li><Link to="/verification-form"><i class="mdi mdi-chevron-right"></i> Verification Form</Link></li>
                                <li><Link to="/login"><i class="mdi mdi-chevron-right"></i> Login</Link></li>
                                <li><Link to="/how-to-apply"><i class="mdi mdi-chevron-right"></i> How To Apply</Link></li>
                               
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="footer-head">
                            <h6 class="title mb-0">ABOUT US</h6>
                        </div>
                        <div class="footer-item mt-4">
                            <ul class="list-unstyled mb-0">
                                <li><a href="https://niais.org/about" target="_blank"><i class="mdi mdi-chevron-right"></i> About</a></li>
                                <li><a href="https://niais.org/upcoming-events" target="_blank"><i class="mdi mdi-chevron-right"></i>Upcoming Events</a></li>
                                <li><a href="https://niais.org/blogs" target="_blank"><i class="mdi mdi-chevron-right"></i>Blogs</a></li>
                                <li><a href="https://niais.org/job" target="_blank"><i class="mdi mdi-chevron-right"></i>Jobs</a></li>
                                <li><a href="https://lms.niais.org/" target="_blank"><i class="mdi mdi-chevron-right"></i> lms</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div class="col-lg-3 col-md-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="footer-head">
                            <h6 class="title mb-0">Newsletter</h6>
                        </div>
                        <div class="footer-news mt-4">
                            <p class="text-foot">Subscribe to our email newsletter to receive useful articles and special offers.</p>
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="emailsubscribe" class="form-control rounded" placeholder="Your email : " required/>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <input type="submit" id="submitsubscribe" name="send" class="btn btn-custom rounded w-100" value="Subscribe"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
                {/* <!-- End Footer Info --> */}
            </div>
        </footer>
        <footer class="footer bg-dark footer-bar py-4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="text-foot mb-0">{new Date().getFullYear()} © NIAIS 4.0</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!-- FOOTER END -->

        <!-- Back to top --> */}
        <a href="#" class="back-to-top rounded text-center" id="back-to-top"> 
            <i class="mdi mdi-chevron-up d-block"> </i> 
        </a>
        {/* <!-- Back to top -->  */}
            </div>
        )
    }
}
