import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteForeverOutlinedIcon   from '@material-ui/icons/DeleteForeverOutlined';
import Axios from 'axios'
import swal from 'sweetalert';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast  } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import {history} from '../history'
import TextField from '@material-ui/core/TextField';
import {Helmet} from "react-helmet";

export default class studentCourses extends Component {
    constructor(props)
    {
        super(props)

      this.state=
        {
            courses:  [],
            isOpen:false,
            isOpen1:false,
            subtitle:{},
            selectedCourses:[],
            totalFee:null,
            challanCourses:[],
            plan:1,
            selectedLongCourses:[],
            selectedShortCourses:[],
            Longcourses:[],
            Shortcourses:[],
            submitted: this.props.location.state,
            
        }
        
        this.addNew = this.addNew.bind(this)
        this.onRemove = this.onRemove.bind(this)
        this.getStudentCourses = this.getStudentCourses.bind(this)
        this.openModal = this.openModal.bind(this)
        this.afterOpenModal = this.afterOpenModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleCourse =this.handleCourse.bind(this)

        this.openModal1 = this.openModal1.bind(this)
        this.afterOpenModal1 = this.afterOpenModal1.bind(this)
        this.closeModal1 = this.closeModal1.bind(this)
        this.paymentPlan = this.paymentPlan.bind(this)
        this.onGetChallan = this.onGetChallan.bind(this)

        this.handleLongCourse =this.handleLongCourse.bind(this)
        this.handleShortCourse =this.handleShortCourse.bind(this)
        toast.configure();
    }
    addNew()
    {
      if(this.state.selectedLongCourses.length + this.state.selectedShortCourses.length === 0)
       {
        this.notifyError('please select Atleast One Course to Add')
       }
       if(this.state.selectedLongCourses.length + this.state.selectedShortCourses.length + this.state.courses.length >  2)
       {
        this.notifyError('You Can Checkout Max 2 Courses')
       }
       
       else{
        var courses = null
        if(this.state.selectedShortCourses.length !=0 && this.state.selectedLongCourses.length ===0)
        {
           courses = this.state.selectedShortCourses;
        }else if(this.state.selectedLongCourses.length !=0 && this.state.selectedShortCourses.length ===0)
        {
           courses = this.state.selectedLongCourses;
        }else{
           courses = this.state.selectedLongCourses +  ','+this.state.selectedShortCourses
        }
        if(this.state.selectedShortCourses){}
        var data = JSON.parse(localStorage.getItem("data"));
        const fd = new FormData(); 
        fd.append('id', data.id);
        fd.append('email', data.email);
        fd.append('courses',courses );
        const url = window.url+'add-new';
         Axios.post( url,fd, { headers: {
                'Content-Type': 'application/json',
            } } )  
            .then(res=>{
              
                if( res.data.success === true ) {
                  this.closeModal()
                  this.notifySuccess('Courses Successfully Updated')
                  this.getStudentCourses()
                }
            })
            .catch(err=>{ 
             
         });


       }
        
    }
   async onGetChallan()
    {
      if(this.state.plan===''){
          this.notifyError('please select Payment Plan')
      }else
      {
      var data = JSON.parse(localStorage.getItem("data"));
      const fd = new FormData(); 
      fd.append('id', data.id);
      fd.append('email', data.email);
      fd.append('plan', this.state.plan);
      const url = window.url+'get-challan';
   await Axios.post( url,fd, { headers: {
           'Content-Type': 'application/json',
       } } )  
       .then(res=>{
         
           if( res.data.success === true ) {
             if(res.data.plan ===1)
             {
              history.push({pathname:'/my-invoice',state:res.data})
             }if(res.data.plan ===2)
             {
              history.push({pathname:'/installment-invoice',state:res.data})
             }
            
           }
           if( res.data.success === false &&  res.data.exist === true) {
             
             history.push('/login')
             swal({
              title: "Already exists",
              text: 'Please Get Your Invoice',
              icon: "error",
              button: "ok",
            });
           }
       })
       .catch(err=>{ 
        
    });
  }
    }
    paymentPlan(e)
    {
       this.setState({plan:e.target.value})
      if(e.target.value === "1")
      {
         
        const total = this.state.courses.reduce((a,v) =>  a = a + v.course.fee , 0 );
       const challanCourse =   this.state.courses.map((course,i)=>{return(
             {courseName:course.course.name,fee:course.course.fee}
         )});
       this.setState({totalFee:total,challanCourses:challanCourse});
       console.log(challanCourse,'challanCourses')
    }
    if(e.target.value === "2")
    {
       
      const percentage = 0.2403
     

      const challanCourse =   this.state.courses.map((course,i)=>{return(
        
        {courseName:course.course.name,fee:course.course.duration==="1-year"?Math.round((course.course.fee * percentage) +course.course.fee):course.course.fee }
    )});
    const grantTotal = challanCourse.reduce((a,v) =>  a = a + v.fee , 0 );
      this.setState({totalFee:Math.round(grantTotal),challanCourses:challanCourse})
  }
    
    }
    handleCourse(event) {
       
          this.setState({selectedCourses:event.target.value});
        };
     openModal() {
       this.setState({isOpen:true});
      }
    
       afterOpenModal() {
        // references are now sync'd and can be accessed.
        // const {subtitle} = this.state.subtitle
        // subtitle.style.color = '#f00';
        // this.setState({subtitle});
      }
    
       closeModal(){
        this.setState({
          isOpen:false,
          selectedLongCourses:[],
          selectedShortCourses:[],
        });
      }

      openModal1() {
        this.setState({isOpen1:true});
       }
     
        afterOpenModal1() {
         // references are now sync'd and can be accessed.
         // const {subtitle} = this.state.subtitle
         // subtitle.style.color = '#f00';
         // this.setState({subtitle});
       }
     
        closeModal1(){
         this.setState({isOpen1:false,totalFee:null});
       }
    componentDidMount()
    {
         
        this.getStudentCourses()
        
    }
   async getStudentCourses()

    {   var data = JSON.parse(localStorage.getItem("data"));
        const fd = new FormData(); 
        fd.append('id', data.id);
        fd.append('email', data.email);
        const url = window.url+'get-student-courses';
     await Axios.post( url,fd, { headers: {
             'Content-Type': 'application/json',
         } } )  
         .then(res=>{
           
             if( res.data.success === true ) {
              this.setState({
                courses:res.data.courses,
                Longcourses:res.data.Longcourses,
                Shortcourses:res.data.Shortcourses,
              });
             
             }
         })
         .catch(err=>{ 
          
      });
    }
    handleLongCourse(event) {
     
        this.setState({selectedLongCourses:event.target.value});
      };
      handleShortCourse(event) {
       
          this.setState({selectedShortCourses:event.target.value});
        };
    onRemove(id)
    {
        const url = window.url+'delete-course/'+ id;
        Axios.get( url, { headers: {
             'Content-Type': 'application/json',
         } } )  
         .then(res=>{
           
             if( res.data.success === true ) {
              this.getStudentCourses()
                swal({
                    title: "Removed",
                    text: res.data.message,
                    icon: "success",
                    button: "ok",
                  });
             
             }
         })
         .catch(err=>{ 
          
      });
    }
        
  notifySuccess(m){

    toast.success(m, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
  notifyError(m){
    toast.error(m, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          };
        const customStyles = {
            content : {
              top                   : '50%',
              left                  : '50%',
              right                 : 'auto',
              bottom                : 'auto',
              marginRight           : '-50%',
              transform             : 'translate(-50%, -50%)'
            }
          };
        return (
            <div>
              {
              this.state.submitted?
              <Helmet>
              <script>
  {`<!-- Meta Pixel Code -->
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1384016242335812');
  fbq('track', 'PageView');
</script>`}
    </script>
    <noscript>{`<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=1384016242335812&ev=PageView&noscript=1"
/>`}</noscript>
            </Helmet>
   
              :""
              }
               <section class="section" id="contact">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-12">
                          <div class="section-title  text-center">
                          <img src="images/logo.png" alt="missing_logo" height="60"/>
                              <p class="title ">National Initiative for Artificial Intelligence and Security</p>
                            
                          </div>
                      </div>
                  </div>
  
                  <div class="row align-items-center">
                     
                      {/* <!-- col end --> */}
                      <div class="col-md-12 mt-4 pt-2">
                          <div class="custom-form">
                        
  
                             
                                  <div class="row">
                                     
                                  <span class="w-100 btn btn-new heading_dis">Your Courses</span>
                                     
                            <div class="col-12 mt-2">
                            <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Course Name</TableCell>
            <TableCell align="right">Duration</TableCell>
            <TableCell align="right">Course Fee</TableCell>
            <TableCell align="right">Action</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.courses.map((course) => (
            <TableRow key={course.course.id}>
              <TableCell component="th" scope="row">
                {course.course.name}
              </TableCell>
              <TableCell align="right">{course.course.duration}</TableCell>
              {
                course.course.discount > 0 ? 
                <TableCell align="right">  <del>{ course.course.fee}</del> {course.course.fee - course.course.discount} </TableCell> :
                <TableCell align="right">{ course.course.fee} </TableCell>
              }
    
              <TableCell align="right"> <DeleteForeverOutlinedIcon color="secondary" onClick={()=>this.onRemove(course.id)}  /></TableCell>
           
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

                      </div>
                      <div class="col-md-6">
                                      <input type="button" onClick={this.openModal} name="send" class="submitBnt btn btn-submit w-100" value="Add New"/>
  
                                          <div id="simple-msg"></div>
                                      </div> 
                                      <div class="col-md-6">
                                      <input type="button" onClick={this.onGetChallan} name="send" class="submitBnt btn btn-checkout w-100" value="Get Invoice"/>
  
                                          <div id="simple-msg"></div>
                                      </div>   
                          </div>
                      
                  </div>
  </div>
                  </div>
              </div>
          </section>
          <Modal
          isOpen={this.state.isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h2>Add New Course</h2>
        
           <div class="row">
          
                                  <span class="w-100 btn btn-new heading_dis">Available Courses</span>
                                  <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">1 Year Programs</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
             value={this.state.selectedLongCourses}
            onChange={this.handleLongCourse}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            // error={this.state.errors.course}
          
          >
            {this.state.Longcourses.map((course) => (
              <MenuItem key={course.id} value={course.name}>
                <Checkbox checked={this.state.selectedLongCourses.indexOf(course.name) > -1} />
                <ListItemText primary={course.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
                                          </div>
                                          
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">Short Courses</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
             value={this.state.selectedShortCourses}
            onChange={this.handleShortCourse}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            // error={this.state.errors.course}
          
          >
            {this.state.Shortcourses.map((course) => (
              <MenuItem key={course.id} value={course.name}>
                <Checkbox checked={this.state.selectedShortCourses.indexOf(course.name) > -1} />
                <ListItemText primary={course.name } />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
                                          </div>
                                          <div class="col-md-6">
                                      <input  onClick={this.closeModal} class="submitBnt btn btn-checkout w-100" value="Close"/>
  
                                          <div id="simple-msg"></div>
                                      </div> 
                                          <div class="col-md-6">
                                      <input type="button" onClick={this.addNew} name="send" class="submitBnt btn btn-submit w-100" value="Add"/>
  
                                          <div id="simple-msg"></div>
                                      </div> 
                                     
                                      
                                      </div>
        </Modal>

        <Modal
          isOpen={this.state.isOpen1}
          onAfterOpen={this.afterOpenModal1}
          onRequestClose={this.closeModal1}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <h2>CheckOut</h2>
        
           <div class="row">
           <div class="col-12 col-md-12 col-sm-6">
          <div class="form-group app-label">
                                          <h6>Select Payment Plan 
          <RadioGroup row aria-label="position" onClick={this.paymentPlan} name="position" defaultValue="top">                               
          <FormControlLabel
           
          value="1"
          control={<Radio color="primary" />}
          label="Full Payment"
          labelPlacement="top"
        />
        <FormControlLabel
         
          value="2"
          control={<Radio color="primary"  />}
          label="Installment"
          labelPlacement="top"
        />
        </RadioGroup>       
         </h6>
                 {this.state.totalFee ?
                 
<div class="col-12 mt-2">
                            <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Course Name</TableCell>
           
            <TableCell align="right">Course Fee</TableCell>
            
           
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.challanCourses.map((course) => (
            <TableRow key={course.courseName}>
              <TableCell component="th" scope="row">
                {course.courseName}
              </TableCell>
             
              <TableCell align="right">{course.fee}</TableCell>
              
           
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                 <h4 style={{float:"right"}}>Total Fee: {this.state.totalFee}</h4> </div>:
                 ""}                       
                                          </div>
                                          </div>
                                          <div class="col-md-6">
                                      <input  onClick={this.closeModal1} class="submitBnt btn btn-checkout w-100" value="Close"/>
  
                                     
                                      </div> 
                                          <div class="col-md-6">
                                      <input type="button" onClick={this.onGetChallan}  class="submitBnt btn btn-submit w-100" value="Get"/>
  
                                         
                                      </div> 
                                     
                                      </div>
        </Modal>
     
    
             </div>
        )
    }
}
