import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import swal from 'sweetalert';
import Axios from 'axios';
import {history} from '../history'
export default class login extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            email:'',
            cnic:'',

                }
                this.onChange =this.onChange.bind(this)
                this.onSubmit =this.onSubmit.bind(this)
    }
    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
          }, function () {
            console.log(this.state,'onchange')
        });
    }
    onSubmit(e)
    {
     e.preventDefault()
     const fd = new FormData(); 
     fd.append('cnic', this.state.cnic);
     fd.append('email', this.state.email);

     const url = window.url+'login';
     Axios.post( url,fd, { headers: {
         'Content-Type': 'application/json',
     } } )  
     .then(res=>{
       if( res.data.success === true )
       {
        swal("Success!", "Successfully Login" , "success");
         const data = {id:res.data.StudentProfile.cnic ,email:res.data.StudentProfile.email}
         localStorage.setItem("data", JSON.stringify(data));
         
        
         if(res.data.challan === null)
           {
             
             history.push('/student-courses')
           }
           else{

            if(res.data.plan === 1)
            {
                history.push({pathname:'/my-invoice',state:res.data})
            }else if(res.data.plan === 2)
            {
                history.push({pathname:'/installment-invoice',state:res.data})

            }
           
               
           }
       }
         else if( res.data.success === false ) {
            swal("Error!", res.data.message , "error");
        //    const data = {id:this.state.cnicOrBayForm ,email:this.state.email}
        //    localStorage.setItem("data", JSON.stringify(data));
           // this.notifySuccess(res.data.message)
         
        //    this.setState({  
        //       submitted: true,
        //       studentCourses:res.data.StudentCourses
        //     });
         }
        //  if( res.data.success === false ) {
        //    this.notifyError(res.data.message)
        //    this.setState({ btnEnable: false });
       
        //   }
     })
     .catch(err=>{ 
       this.setState({ btnEnable: false });
  });
     
    }
    render() {
        return (
            <div>
            <section class="section login" id="contact" >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title  text-center">
                    <img src="images/logo.png" alt="missing_logo" height="60"/>
                        <h3 class="title text-uppercase ">Login Form</h3>
                      
                    </div>
                </div>
            </div>

            <div class="row align-items-center">
               
                {/* <!-- col end --> */}
                <div class="col-md-12 mt-4 pt-2">
                    <div class="custom-form">
                  

                        <form method="post" onSubmit={this.onSubmit} >
                            <div class="row">
                               
                            <div class="col-md-12">
                                    <div class="form-group app-label">
                                    <FormControl fullWidth >
      
                                   <TextField
                                      onChange={this.onChange}
                                       label="CNIC"
                                      name="cnic"
                                      type="text"
                                      required
                                      />
                                </FormControl>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group app-label">
                                    <FormControl fullWidth >
      
                                   <TextField
                                  onChange={this.onChange}
                                       label="Email"
                                        type="email"
                                        name="email"
                                        required
                                      />
                                </FormControl>
                                    </div>
                                </div>         
                               </div>
                            <div class="row">
                                <div class="col-md-12">
                                <input type="submit" id="submit" name="send" class="submitBnt btn btn-submit w-100" value="Login"/>

                                    <div id="simple-msg"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section> 
        </div>

        )
    }
}
