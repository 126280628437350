import React from "react"
import { Redirect } from "react-router-dom"
import { toast  } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
    }

    toast.configure();
  }
    notifySuccess(){
      toast.success("You have been successfully logged out!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  render() {
      
       localStorage.clear();
       this.notifySuccess()
      return <Redirect to="/" />;
  
  }
}
export default Logout
