import React, { Component } from 'react'
import Axios from 'axios'

export default class planOneChallan extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: this.props.location.state
		}
		this.getChallan = this.getChallan.bind(this)
	}
	componentDidMount() {
		console.log(this.state, 'challan-state')
	}
	getChallan() {

	}
	render() {
		window.scrollTo(0, 0);

		return (
			<div>
				<section class="section" id="contact">
					<div class="container">
						<div class="row justify-content-center">
							<div class="col-12"></div>
							<div class="print-container clearfix">
								<div class="header">
									<div class="content">
										<table style={{ width: 100 + "%" }}>
											<tr style={{ width: 100 + "%" }} class="heading">
												<td colspan="3">

													<img class="invoice-logo" src="images/logo.png" height="60" alt="" />
												</td>
												<td class="text-right">
													<div class="invoice-from" style={{ maxWidth: 400 + "px" }}>

														<h3 class="mega">INVOICE</h3>
														<h6 class="grey">National Initiative for Artificial Intelligence and Security.</h6>
														<h6><a href='https://www.youtube.com/watch?v=7bivt0xOQc8' className='text-danger'>How To Pay ?</a></h6>
													</div>

												</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="body">


									<h4>Details</h4>
									<div class="break-d">
										<strong>Invoice No:</strong> {this.state.data.challan.challan_no}
										<br />

										<p><strong>Student Name:</strong> {this.state.data.StudentProfile.name}
										</p>
										<p><strong>Status:</strong> {this.state.data.challan.is_paid ? <span className='badge bg-success text-light'>Paid</span> : this.state.data.challan.is_requested ? <span className='badge bg-warning text-light'>Requested</span> : <span className='badge bg-danger text-light'>Unpaid</span>}
										</p>
										<table class="table summary-table">
											<thead>
												<tr>
													<th>Course Name</th>
													<th>Fee </th>

												</tr>
											</thead>
											<tbody>
												{this.state.data.StudentCourses.map((course, i) => {
													return (
														<tr class="simple">
															<th scope="row">{course.courses.name}</th>
															{
																course.courses.discount > 0 ?
																	<td><del>{course.courses.fee}</del> {course.fee}</td> :
																	<td>{course.courses.fee}</td>
															}



														</tr>)
												})}

											</tbody>
										</table>
										<div class="row">
											<div class="col-md-12">
												<div class="other-rates clearfix">
													<dl class="dl-horizontal clearfix no-border">
														<dt class="blue">Total</dt>
														<dd><b>{this.state.data.challan.amount}</b></dd>
													</dl>
												</div>
											</div>
											<div class="col-md-12">
												<strong>
												Please note the exact steps of “How to pay fee from Any Bank Any App”.</strong>
 <ul>
	<li>
 Login to your mobile banking app using your credentials (Username and Password).
 </li>
 <li>
 Once logged in, look for the option related to payments or making payments. This may be located in the main menu or under a specific section like "Bill Pay" or "Payments."
 </li>
 <li>
 Select 1 Bill Invoice and Enter your Voucher Number Or go to Bill payment and add your 1 Bill invoice.
 </li>
 <li>
 Enter the necessary details for the payment. This typically includes the recipient's information, such as the biller name, account number, or voucher number. Ensure you provide accurate information to avoid any issues.
 </li>
 <li>
 Verify the payment details to confirm that everything is correct. Double-check the recipient's information, payment amount, and any additional details.
 </li>
 <li>
	 Proceed with the payment by confirming the transaction. Depending on your banking app, you may be required to enter a One-Time Password (OTP) or use biometric authentication for security purposes.
	 </li>
 <li>
 Once the payment is successfully processed, you should receive a confirmation message or notification stating that the payment was completed.
 </li>
 
	 </ul>
	 <p>IF YOU HAVE ANY QUERY CONTACT US : <b>0313-4444059</b></p>
		

											</div>
											<div class="col-md-12">
												<button className="submitBnt btn btn-submit w-100" onClick={() => window.print()}>
													Print
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div></div></section>
			</div>
		)
	}
}
