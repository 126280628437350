import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { toast  } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";

export default class applicationform extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            selectedLongCourses:[],
            selectedShortCourses:[],
            errors: {},
            fullName:'',
            email: '',
            cnicOrBayForm:'',
            number:'',
            gender:'',
            dateOfBirth:'',
            provinces:[],
            selectedProvince:'',
            cities:[],
            selectedCity:'',
            address:'',
            qualifications:[],
            SelectedQualification:'',
            qualificationFields:[],
            SelectedqualificationField:'',
            institute:'',
            Longcourses:[],
            Shortcourses:[],
            submitted:false,
            studentCourses:[],
            isOpen:true,
            date:"",
            validEmailRegex : RegExp(
              /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
            ),

        }
        this.handleLongCourse =this.handleLongCourse.bind(this)
        this.handleShortCourse =this.handleShortCourse.bind(this)
        this.onChange =this.onChange.bind(this)
        this.onChangeProvince =this.onChangeProvince.bind(this)
        this.onChangeQualification =this.onChangeQualification.bind(this)
        this.onSubmit =this.onSubmit.bind(this)
        this.getInitailData =this.getInitailData.bind(this)
        toast.configure();
    }
    
  notifySuccess(m){

    toast.success(m, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
  notifyError(m){
    toast.error(m, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
    componentDidMount()
    {
      fetch('/date.json') // Use the relative path to your JSON file
      .then((response) => response.json())
      .then((result) => {
        this.setState({date:result.date},function(){
          Swal.fire({
            title: 'Discount!',
            text: '',
            html: `<span class="text-dark">Enroll Now & Get <span class="blink_me">50% Discount</span> <br> Till <span class="text-danger">${this.state.date}</span></span>`,
            subText:"",
            imageUrl: 'https://i.ibb.co/x2389g8/Screenshot-2023-09-20-114405.png',
            imageWidth: 150,
            imageHeight: 120,
            imageAlt: 'Custom image',
            confirmButtonText: 'Got it',
            confirmButtonColor: '#388af5',
          })
        })
       
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
     
      this.getInitailData()
      window.scrollTo(0, 0);
    }
   async getInitailData()
  {
    const url = window.url+'get-initial-data';
  await Axios.get( url, { headers: {
        'Content-Type': 'application/json',
    } } )  
    .then(res=>{
      
        if( res.data.success === true ) {
    
          this.setState({
            isOpen:res.data.open,
            provinces:res.data.provinces,
            qualifications:res.data.qualifications,
            Longcourses:res.data.Longcourses,
            Shortcourses:res.data.Shortcourses,
             },function(){
                console.log(this.state,'after-initial-data')
             });
        }
    })
    .catch(err=>{ 
     
 });

  }
    onSubmit(e)
    {
      e.preventDefault();
        if(this.state.selectedLongCourses.length + this.state.selectedShortCourses.length ===0)
        {
          this.notifyError('please Select at least one Course')
          let errors =  this.state.errors
          errors.course =true
          this.setState({errors});
        }else if(this.state.selectedLongCourses.length + this.state.selectedShortCourses.length > 2)
             {
              this.notifyError('You Can Select Max 2 Courses')
              let errors =  this.state.errors
              errors.course =true
              this.setState({errors});
             }
        else{
          var courses = null
          if(this.state.selectedShortCourses.length !=0 && this.state.selectedLongCourses.length ===0)
          {
             courses = this.state.selectedShortCourses;
          }else if(this.state.selectedLongCourses.length !=0 && this.state.selectedShortCourses.length ===0)
          {
             courses = this.state.selectedLongCourses;
          }else{
             courses = this.state.selectedLongCourses +  ','+this.state.selectedShortCourses
          }
          const fd = new FormData(); 
          fd.append('name', this.state.fullName);
          fd.append('cnic', this.state.cnicOrBayForm);
          fd.append('gender', this.state.gender);
          fd.append('dob', this.state.dateOfBirth);
          fd.append('email', this.state.email);
          fd.append('courses', courses);
          fd.append('city', this.state.selectedCity);
          fd.append('mobile_number', this.state.number);
          fd.append('address', this.state.address);
          fd.append('institute', this.state.institute);
          fd.append('qualification', this.state.SelectedqualificationField);

          const url = window.url+'store-student-profile';
          Axios.post( url,fd, { headers: {
              'Content-Type': 'application/json',
          } } )  
          .then(res=>{
            
              if( res.data.success === true ) {
                swal({
                  title: "Congratulations",
                  text: "Your Profile has been Created Successfully!",
                  icon: "success",
                  button: "ok",
                });
                const data = {id:this.state.cnicOrBayForm ,email:this.state.email}
                localStorage.setItem("data", JSON.stringify(data));
                // this.notifySuccess(res.data.message)
              
                this.setState({  
                   submitted: true,
                   studentCourses:res.data.StudentCourses
                 });
              }
              if( res.data.success === false ) {
                this.notifyError(res.data.message)
                this.setState({ btnEnable: false });
            
               }
          })
          .catch(err=>{ 
            this.setState({ btnEnable: false });
       });
          
        }

    }

    onChangeProvince(event)
    {
      const id = event.target.value
      this.setState({
        selectedProvince: id
      }, function () {
        const url = window.url+'get-cities/'+ id;
         Axios.get( url, { headers: {
              'Content-Type': 'application/json',
          } } )  
          .then(res=>{
            
              if( res.data.success === true ) {
          
                this.setState({
                  cities:res.data.cities,
                   },function(){
                      console.log(this.state,'after-city-data')
                   });
              }
          })
          .catch(err=>{ 
           
       });
        

    });
    }
    onChangeQualification(event)
    {
      const id = event.target.value
      this.setState({
        SelectedQualification: id
      }, function () {
        const url = window.url+'get-qualification-field/'+ id;
        Axios.get( url, { headers: {
             'Content-Type': 'application/json',
         } } )  
         .then(res=>{
           
             if( res.data.success === true ) {
         
               this.setState({
                qualificationFields:res.data.qualificationFields,
                  },function(){
                     console.log(this.state,'after-qualif-data')
                  });
             }
         })
         .catch(err=>{ 
          
      });
       
    });
    }
    onChange(event) {


      event.preventDefault();
      const { name, value } = event.target;
      this.setState({
        [name]: value
      }, function () {
        console.log(this.state,'onchange')
    });
      let errors = this.state.errors;
  
      switch (name) {
        case 'fullName': 
          errors.fullName = 
            value.length < 3
              ? 'Full Name must be at least 3 characters long!'
              : '';
          break;
        case 'email': 
          errors.email = 
            this.state.validEmailRegex.test(value)
              ? ''
              : 'Please Enter Valid Email!';
          break;
        case 'cnicOrBayForm': 
          errors.cnicOrBayForm = 
            value.length != 13
              ? 'CNIC must be 13 digits long'
              : '';
          break;
          
          case 'number': 
          errors.mobileNumber = 
            value.length != 11
              ? 'Mobile Number must be 11 digits long'
              : '';
          break;
          case 'address': 
          errors.address = 
            value.length <= 3
              ? 'Required'
              : '';
          break;
          case 'institute': 
          errors.institute = 
            value.length <= 3
              ? 'Required'
              : '';
          break;
        default:
          break;
      }
  
      this.setState({errors, [name]: value});
    }
     handleLongCourse(event) {
      let errors =  this.state.errors
       errors.course =""
        this.setState({selectedLongCourses:event.target.value,errors});
      };
      handleShortCourse(event) {
        let errors =  this.state.errors
         errors.course =""
          this.setState({selectedShortCourses:event.target.value,errors});
        };
    render() {
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          };
          if(!this.state.isOpen){
          return (
            <section class="section" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title  text-center">
                        <img src="images/logo.png" alt="missing_logo" height="60"/>
                            <h1 class="text-danger">Admissions Are Closed!</h1>
                            <h4>For detail contact us @ <a href="callto:03024444059">0302-4444059</a></h4>
                        </div>
                    </div>
                </div>
                </div>
                </section>
          )
          
          }
          if(!this.state.submitted)
          {
            return (
              <div>
                 {/* <!-- CONTACT START --> */}
          <section class="section" id="contact">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-12">
                          <div class="section-title  text-center">
                          <img src="images/logo.png" alt="missing_logo" height="60"/>
                              <h3 class="title text-uppercase ">Application Form</h3>
                            
                          </div>
                      </div>
                  </div>
                  
                  <div class="row align-items-center">
                     
                      {/* <!-- col end --> */}
                      <div class="col-md-12 mt-4 pt-2">
                          <div class="custom-form">
                        
  
                              <form method="post" onSubmit={this.onSubmit} >
                                  <div class="row">
                                     
                                  <span class="w-100 btn btn-new heading_dis" value="">Personal Profile</span>
                                      <div class="col-md-4">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
                                         <TextField
                                       
                                             label="Full Name"
                                        required
                                        name="fullName"
                                          type="text"
                                          onChange={this.onChange}
                                          error={this.state.errors.fullName}
                                          helperText={this.state.errors.fullName}
                                            />
                                      </FormControl>
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
                                         <TextField
                                             name="email"
                                             label="Email"
                                              type="email"
                                              required
                                              onChange={this.onChange}
                                              error={this.state.errors.email}
                                              helperText={this.state.errors.email}
                                            />
                                      </FormControl>
                                          </div>
                                      </div>
                                     
                                      <div class="col-md-4">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
                                         <TextField
                                             name="cnicOrBayForm"
                                             type="number"
                                             label="CNIC / B-Form-Number"
                                             required
                                             onChange={this.onChange}
                                             error={this.state.errors.cnicOrBayForm}
                                             helperText={this.state.errors.cnicOrBayForm}
                                            />
                                      </FormControl>
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
                                         <TextField
                                             name="number"
                                             type="number"
                                             label="Mobile Number"
                                             required
                                             onChange={this.onChange}
                                             error={this.state.errors.mobileNumber}
                                             helperText={this.state.errors.mobileNumber}
                                            />
                                      </FormControl>
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
            <TextField
            name="dateOfBirth"
            type="date"
            focused
                label="Date Of Birth"
                required
                onChange={this.onChange}
  
               />
         </FormControl>
                                          </div>
                                      </div>
                                      <div class="col-md-4">
                                      <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">Gender *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="gender"
            required={true}
            error={this.state.errors.gender}
            value={this.state.gender}
          onChange={this.onChange}
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
           
          </Select>
        </FormControl>
                                      </div>
                                      <div class="col-md-6">
                                      <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">Province *</InputLabel>
          <Select
          name="provice"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
           value={this.state.selectedProvince}
            onChange={this.onChangeProvince}
          >
            {this.state.provinces.map((province,i)=>{return( <MenuItem value={province.id}>{province.name}</MenuItem>)})}
           
          
           
          </Select>
        </FormControl>
                                      </div>
                                      <div class="col-md-6">
                                      <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">City *</InputLabel>
          <Select
          name="selectedCity"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            required={true}
            value={this.state.selectedCity}
            onChange={this.onChange}
          >
            {this.state.cities.map((city,i)=>{return(<MenuItem value={city.name}>{city.name}</MenuItem>)})}
            
         
           
          </Select>
        </FormControl>
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
            <TextField
            name="address"
            type="text"
           label="Address"
           required
           onChange={this.onChange}
           error={this.state.errors.address}
           helperText={this.state.errors.address}
                
               />
         </FormControl>
                                          </div>
                                      </div>
                                     
                                      <span class="w-100 btn btn-new heading_dis" value="">Education</span>
                                      <div class="col-md-6">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">Select Qualification *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.SelectedQualification}
            onChange={this.onChangeQualification}
          >
            {this.state.qualifications.map((qualification,i)=>{return(<MenuItem value={qualification.id}>{qualification.name}</MenuItem>)})}
            
           
          </Select>
        </FormControl>
                                          </div>
                                          
                                      </div>
                                      <div class="col-md-6">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
          <InputLabel id="demo-simple-select-label">Qualification Field *</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            required={true}
            name="SelectedqualificationField"
            value={this.state.SelectedqualificationField}
            onChange={this.onChange}
          >
            {this.state.qualificationFields.map((qualificationField,i)=>{return( <MenuItem value={qualificationField.name}>{qualificationField.name}</MenuItem>)})}
           
            
           
          </Select>
        </FormControl>
                                          </div>
                                          </div>
                                      <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth >
            
                                         <TextField
                                           name="institute"
                                             label="Institute Name"
                                              type="text"
                                              required
                                              onChange={this.onChange}
                                              error={this.state.errors.institute}
                                              helperText={this.state.errors.institute}
                                            />
                                      </FormControl>
                                          </div>
                                      </div>
                                  <span class="w-100 btn btn-new heading_dis" value="">Available Courses</span>
                                  <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">{ this.state.errors.course ? this.state.errors.course :" 6 Months Programs"}</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
             value={this.state.selectedLongCourses}
            onChange={this.handleLongCourse}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            error={this.state.errors.course}
          
          >
            {this.state.Longcourses.map((course) => (
              <MenuItem key={course.id} value={course.name}>
                <Checkbox checked={this.state.selectedLongCourses.indexOf(course.name) > -1} />
                <ListItemText primary={course.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
                                          </div>
                                          
                                      </div>
                                      <div class="col-md-12">
                                          <div class="form-group app-label">
                                          <FormControl fullWidth>
          <InputLabel id="demo-mutiple-checkbox-label">{ this.state.errors.course ? this.state.errors.course :" Short Courses"}</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
             value={this.state.selectedShortCourses}
            onChange={this.handleShortCourse}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            error={this.state.errors.course}
          
          >
            {this.state.Shortcourses.map((course) => (
              <MenuItem key={course.id} value={course.name}>
                <Checkbox checked={this.state.selectedShortCourses.indexOf(course.name) > -1} />
                <ListItemText primary={course.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
                                          </div>
                                          
                                      </div>
                          

                                  </div>
                                  <div class="row">
                                      <div class="col-md-12">
                                      <input type="submit" id="submit" name="send" class="submitBnt btn btn-submit w-100" value="Submit Form"/>
  
                                          <div id="simple-msg"></div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
  
                  
              </div>
          </section>
          {/* <!-- CONTACT END --> */}
  
              </div>
          )
          }else{

            return <Redirect to={{pathname:"student-courses",state:true}}/>


          }
       
    }
}
